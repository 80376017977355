import MusicNoteIcon from '@mui/icons-material/MusicNote';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import Window from '@mui/icons-material/Window';
import Warning from '@mui/icons-material/Dns';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import Spa from '@mui/icons-material/Spa';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

export const logo = 'https://i.ibb.co/s9Qys2j/logo.png';


export const categories = [
  { name: '"adfn656"', icon: <HomeIcon />, },
  { name: 'CS224N', icon: <CodeIcon />, },
  { name: 'Stats: 110', icon: <Warning />, },
  { name: 'KIPAC', icon: <Window />, },
  { name: 'Royal Insti', icon: <Spa />, },
  { name: 'YaleCourses', icon: <MusicNoteIcon /> },
  { name: 'MIT-OCW', icon: <SchoolIcon />, },
  { name: 'Lex Fridman', icon: <GraphicEqIcon />, },
  { name: 'Quanta Mag', icon: <OndemandVideoIcon />, },
  { name: 'YCombinator', icon: <SportsEsportsIcon />, },
  { name: 'Cornell Ornith', icon: <LiveTvIcon />, },
  { name: 'minutephysics', icon: <FitnessCenterIcon />, },
  { name: 'Nathan Kutz', icon: <CheckroomIcon />, },
  { name: 'World Sci Fes', icon: <FaceRetouchingNaturalIcon />, },
  { name: 'Thrasher', icon: <TheaterComedyIcon />, },
  { name: 'Go Pro', icon: <FitnessCenterIcon />, },
  { name: 'Dapz Academy', icon: <DeveloperModeIcon />, },
];

export const demoThumbnailUrl = 'https://i.ibb.co/G2L2Gwp/API-Course.png';
export const demoChannelUrl = '/playlist?list=PL9zwdW4FtAX5IV6DBFi3dCvyV8pQKBuhU';
export const demoVideoUrl = '/video/GDa8kZLNhJ4';
export const demoChannelTitle = 'JavaScript Mastery';
export const demoVideoTitle = 'Build and Deploy 5 JavaScript & React API Projects in 10 Hours - Full Course | RapidAPI';
export const demoProfilePicture = 'http://dergipark.org.tr/assets/app/images/buddy_sample.png'